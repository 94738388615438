import { useApolloMutation } from './graphql/Mutation';
import { CreateResendInvoicesInput } from 'src/types/request/CreateResendInvoicesInput';
import { CREATE_RESEND_INVOICE } from 'src/graphql/schema/mutation/CreateResendInvoice';
import { SEARCH_REJECTED_INVOICES } from 'src/graphql/schema/query/SearchRejectedInvoices';

export const useCreateResendInvoices = () => {
  const apolloMutation = useApolloMutation();

  const createResendInvoices = (request: CreateResendInvoicesInput, employerId: string, customerId: string) =>
    apolloMutation(CREATE_RESEND_INVOICE, employerId, { request }, [SEARCH_REJECTED_INVOICES], customerId);
  return createResendInvoices;
};
