import React, { FC, useMemo, useState } from 'react';
import { Invoice, InvoiceStatusEnum } from 'src/types/Invoice';
import { Paging } from 'src/types/PageableResponse';
import { getTotalPages } from 'src/util/Pagination/Pagination';
import { useCreatePaymentMessages } from 'src/service/CreatePaymentMessages';
import { usePatchInvoiceHeader } from 'src/service/PatchInvoiceHeader';
import InvoicesFilterDetails from '../InvoicesFilterDetails';
import { Button, Icon, Pagination, SortDirection } from 'fgirot-k2-ui-components';
import InvoicesTable from '../InvoicesTable';
import InvoicesCalculatedAmount from '../InvoicesCalculatedAmount';
import SearchInvoicesFiltersModal from '../SearchInvoicesFiltersModal';
import { useTranslation } from 'react-i18next';
import { groupInvoiceByCustomer, groupInvoiceByEmployer } from '../utils/invoiceUtils';
import { InvoicesTableSortField } from '../InvoicesTable/InvoicesTableSortField';
import SearchInput from '../../SearchInput';
import { SearchInvoicesFilter } from 'src/types/invoice/SearchInvoicesFilter';

interface InvoicesProps {
  invoices: Invoice[];
  paging: Paging;
  pageNumber: number;
  pageSize: number;
  sortProperty?: InvoicesTableSortField;
  sortDirection: SortDirection;
  searchInvoicesFilter: SearchInvoicesFilter;
  searchTextInput: string;
  onTextSearch: (searchInput: string) => void;
  onApplyFilters: (filters: SearchInvoicesFilter) => void;
  onApplySorting: (property: string, sortDirection: SortDirection) => void;
  onPageChange: (pageSize: number) => void;
}

const Invoices: FC<InvoicesProps> = ({
  invoices,
  paging,
  pageNumber,
  pageSize,
  sortProperty,
  sortDirection,
  searchInvoicesFilter,
  searchTextInput,
  onTextSearch,
  onApplyFilters,
  onApplySorting,
  onPageChange,
}) => {
  const { t } = useTranslation();

  const totalPages = useMemo(
    () => getTotalPages(paging.totalNumberOfElements, pageSize),
    [paging.totalNumberOfElements, pageSize],
  );
  const [checked, setChecked] = useState<string[]>([]);
  const [sentPayments, setSentPayments] = useState<string[]>([]);
  const [pausedInvoices, setPausedInvoices] = useState<string[]>([]);
  const createPaymentMessages = useCreatePaymentMessages();
  const patchInvoiceHeader = usePatchInvoiceHeader();

  const [filtersModalOpen, setFiltersModalOpen] = useState(false);

  const handleCloseModal = () => {
    setFiltersModalOpen(false);
  };

  const handleModalOpen = () => {
    setFiltersModalOpen(true);
  };

  const toggleChecked = (id: string) => {
    if (checked.includes(id)) {
      setChecked((prev) => prev.filter((c) => c !== id));
    } else {
      setChecked((prev) => [...prev, id]);
    }
  };

  const createPayments = () => {
    if (checked.length > 0) {
      const checkedInvoices = invoices.filter((invoice) => {
        return checked.includes(invoice.id);
      });
      const groupedByCustomer = groupInvoiceByCustomer(checkedInvoices);
      groupedByCustomer.forEach((customerGroup) => {
        const groupedByEmployer = groupInvoiceByEmployer(customerGroup);
        groupedByEmployer.forEach((employerGroup) => {
          const invoicesToSend = employerGroup.map((invoice) => invoice.id);
          createPaymentMessages(
            { invoices: invoicesToSend },
            employerGroup[0].employer.id,
            employerGroup[0].customerId,
          );
          setSentPayments((prev) => [...prev, ...invoicesToSend]);
        });
      });
      setChecked([]);
    }
  };

  const patchInvoiceHeaderStatus = (status: string) => {
    const invoicesToPatch = checked.map((invoiceId) => ({
      invoiceHeaderId: invoiceId,
      invoiceStatus: status,
    }));
    patchInvoiceHeader(invoicesToPatch, invoices.find((i) => checked.find((cI) => cI === i.id))?.employer.id);

    if (status === InvoiceStatusEnum.PAUSED) {
      setPausedInvoices((prev) => [...prev, ...checked]);
    } else {
      setPausedInvoices((prev) => prev.filter((invoiceId) => !checked.includes(invoiceId)));
    }
    setChecked([]);
  };

  const toggleAll = () => {
    if (checked.length === invoices.length && invoices.every((invoice) => checked.includes(invoice.id))) {
      setChecked([]);
    } else {
      setChecked(invoices.map((invoice) => invoice.id));
    }
  };

  const remainingInvoices = invoices.filter((i) => !sentPayments.includes(i.id));

  const getSelectedInvoiceStatuses = () => {
    return checked.map((invoiceId) => invoices.find((invoice) => invoice.id === invoiceId)?.invoiceStatus);
  };

  const allSelectedArePrepared = getSelectedInvoiceStatuses().every(
    (status) => status === InvoiceStatusEnum.PREPARED_FOR_PAYMENTS,
  );
  const allSelectedArePaused = getSelectedInvoiceStatuses().every((status) => status === InvoiceStatusEnum.PAUSED);
  const mixedSelection =
    getSelectedInvoiceStatuses().includes(InvoiceStatusEnum.PAUSED) &&
    getSelectedInvoiceStatuses().includes(InvoiceStatusEnum.PREPARED_FOR_PAYMENTS);

  const isButtonDisabled = (condition: boolean) => checked.length === 0 || condition || mixedSelection;

  return (
    <>
      <div className="invoices-tab">
        <SearchInput
          onSearch={onTextSearch}
          searchInput={searchTextInput}
          placeHolder={t('economy:invoices-tab.filters.search-placeholder')}
          type={'text'}
        />
        <InvoicesFilterDetails filters={searchInvoicesFilter} applyFilters={onApplyFilters} />
        <div className="invoices-tab__mark-button">
          <Button type="primary" label={t('economy:invoices-tab.buttons.mark-button')} onClick={toggleAll} />
        </div>
        <div className="invoices-tab__table-buttons">
          <Button
            type="primary"
            label={t('economy:invoices-tab.buttons.send-button')}
            onClick={createPayments}
            disabled={isButtonDisabled(!allSelectedArePrepared)}
          />

          <Button
            type="primary"
            data-testid="invoices-pause-button"
            label={t('economy:invoices-tab.buttons.pause-button')}
            onClick={() => patchInvoiceHeaderStatus(InvoiceStatusEnum.PAUSED)}
            disabled={isButtonDisabled(!allSelectedArePrepared)}
          />

          <Button
            type="primary"
            label={t('economy:invoices-tab.buttons.resume-button')}
            onClick={() => patchInvoiceHeaderStatus(InvoiceStatusEnum.PREPARED_FOR_PAYMENTS)}
            disabled={isButtonDisabled(!allSelectedArePaused)}
          />
        </div>
        <InvoicesTable
          invoices={remainingInvoices}
          onChange={toggleChecked}
          checked={checked}
          applySorting={onApplySorting}
          sortingProperty={sortProperty}
          sortDirection={sortDirection}
        />
        <InvoicesCalculatedAmount invoices={remainingInvoices.filter((invoice) => checked.includes(invoice.id))} />
      </div>
      {filtersModalOpen && (
        <SearchInvoicesFiltersModal
          open={filtersModalOpen}
          onClose={handleCloseModal}
          onApplyFilters={onApplyFilters}
          filters={searchInvoicesFilter}
        />
      )}
      {totalPages > 1 && <Pagination currentPage={pageNumber} onPageChange={onPageChange} totalPages={totalPages} />}
      <div className="invoices-tab__filter-button">
        <Button
          type="floating-action-button"
          icon={<Icon type={'Sliders'} stroke="white" />}
          label={t('common:filter')}
          onClick={handleModalOpen}
          data-cy="filter-button"
        />
      </div>
    </>
  );
};

export default Invoices;
