import { Button, Typography } from 'fgirot-k2-ui-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FilterButton from 'src/components/Common/FilterButton';
import './rejected-invoices-filter-details.scss';
import { SearchRejectedInvoicesFilters } from 'src/types/invoice/SearchRejectedInvoicesFilters';

interface RejectedInvoicesFilterDetailsProps {
  filters: SearchRejectedInvoicesFilters;
  applyFilters: (filters: SearchRejectedInvoicesFilters) => void;
}

export const RejectedInvoicesFilterDetails: FC<RejectedInvoicesFilterDetailsProps> = ({ filters, applyFilters }) => {
  const { t } = useTranslation();
  const filtersApplied =
    filters?.createdDate?.from?.date?.length > 0 ||
    filters?.createdDate?.to?.date?.length > 0 ||
    filters?.rejectedBy?.length > 0;

  const removeFromCreatedDateFilter = () => {
    applyFilters({
      ...filters,
      createdDate: {
        from: { date: '', time: '' },
        to: filters?.createdDate?.to,
      },
    });
  };

  const removeToCreatedDateFilter = () => {
    applyFilters({
      ...filters,
      createdDate: {
        from: filters?.createdDate?.from,
        to: { date: '', time: '' },
      },
    });
  };

  const removeRejectedByFilter = () => {
    applyFilters({
      ...filters,
      rejectedBy: [],
    });
  };

  const removeAllFilters = () => {
    applyFilters({
      rejectedBy: [],
      createdDate: {
        from: {
          date: '',
          time: '',
        },
        to: {
          date: '',
          time: '',
        },
      },
      invoiceStatus: ['REJECTED'],
    });
  };

  return (
    <div className="rejected-invoices-filter-details">
      <Typography variant="caption" bold>
        Filter:
      </Typography>
      {!filtersApplied && (
        <Typography variant="caption" italic>
          Inga filter
        </Typography>
      )}
      {filtersApplied && (
        <>
          {filters?.createdDate?.from?.date?.length > 0 && (
            <FilterButton
              label={
                `${filters?.createdDate?.from?.date} ${
                  filters?.createdDate?.from?.time === undefined ? '' : filters?.createdDate?.from?.time
                } ` + t('economy:search-rejected-invoices-filters.rejection-created-start-date')
              }
              onClick={removeFromCreatedDateFilter}
            />
          )}
          {filters?.createdDate?.to?.date?.length > 0 && (
            <FilterButton
              label={
                `${filters?.createdDate?.to?.date} ${
                  filters?.createdDate?.to?.time === undefined ? '' : filters?.createdDate?.to?.time
                } ` + t('economy:search-rejected-invoices-filters.rejection-created-end-date')
              }
              onClick={removeToCreatedDateFilter}
            />
          )}

          {filters?.rejectedBy?.length > 0 && (
            <FilterButton
              label={filters?.rejectedBy?.length + ' ' + t('economy:search-rejected-invoices-filters.rejected-by')}
              onClick={removeRejectedByFilter}
            />
          )}
          {
            <Button
              type="warning"
              size="small"
              label={t('economy:search-handled-invoices-filters.remove-all')}
              onClick={removeAllFilters}
            />
          }
        </>
      )}
    </div>
  );
};

export default RejectedInvoicesFilterDetails;
