import { TableBody, TableHead } from 'fgirot-k2-ui-components';
import React, { FC, useState } from 'react';
import TableBase from 'src/components/Common/TableBase';
import { RejectedInvoicesTableSortField } from './RejectedInvoicesTableSortField';
import { Invoice } from 'src/types/Invoice';
import RejectedInvoicesTableRow from './RejectedInvoicesTableRow';
import RejectedInvoicesTableHeads from './RejectedInvoicesTableHeads';
import './rejected-invoices-table.scss';
import RejectedInvoiceDrawer from '../RejectiedInvoiceDrawer';

interface RejectedInvoicesTableProps {
  invoices: Invoice[];
  checked: string[];
  onChange: (id: string) => void;
  onRowClick: (id: string) => void;
  sortingProperty;
  sortDirection;
  applySorting;
}

const RejectedInvoicesTable: FC<RejectedInvoicesTableProps> = ({
  invoices,
  checked,
  onChange,
  sortingProperty,
  sortDirection,
  applySorting,
}) => {
  const [selectedInvoice, setSelectedInvoice] = useState<string>(undefined);
  const handleOpenRejectedInvoiceDrawer = (id: string) => {
    setSelectedInvoice(id);
  };
  const handleSort = (property: RejectedInvoicesTableSortField) => {
    if (property == sortingProperty) {
      applySorting(property, sortDirection == 'UP' ? 'DOWN' : 'UP');
    } else {
      applySorting(property, sortDirection);
    }
  };
  const handleCloseRejectedInvoiceDrawer = () => {
    setSelectedInvoice(undefined);
  };

  return (
    <>
      <div className="rejected-invoices-table">
        <TableBase compressed>
          <TableHead filled>
            <RejectedInvoicesTableHeads
              sortField={sortingProperty}
              sortDirection={sortDirection}
              onApplySorting={handleSort}
            />
          </TableHead>
          <TableBody>
            {invoices.map((invoice) => (
              <RejectedInvoicesTableRow
                key={`RejectedInvoicesTableRow-${invoice.id}`}
                invoice={invoice}
                onRowClick={(id) => handleOpenRejectedInvoiceDrawer(id)}
                onChange={() => onChange(invoice.id)}
                checked={checked.includes(invoice.id)}
              />
            ))}
          </TableBody>
        </TableBase>
      </div>

      {selectedInvoice !== undefined && (
        <RejectedInvoiceDrawer
          open={selectedInvoice !== undefined}
          onClose={handleCloseRejectedInvoiceDrawer}
          invoice={invoices.find((invoice) => invoice.id === selectedInvoice)}
        />
      )}
    </>
  );
};

export default RejectedInvoicesTable;
