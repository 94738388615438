import { SortDirection, TableRow, Th } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TableHeadProps } from 'src/types/TableHeadProps';
import { RejectedInvoicesTableSortField } from './RejectedInvoicesTableSortField';

interface RejectedInvoicesTableHeadsProps {
  sortDirection: SortDirection;
  sortField: RejectedInvoicesTableSortField;
  onApplySorting(property: RejectedInvoicesTableSortField);
}

const RejectedInvoicesTableHeads: FC<RejectedInvoicesTableHeadsProps> = ({
  sortDirection,
  sortField,
  onApplySorting,
}) => {
  const { t } = useTranslation();
  const tableHeads: TableHeadProps[] = [
    {
      title: t(''),
      fieldName: 'checkbox',
    },
    {
      title: t('economy:rejected-invoices-tab.table-head.employer'),
      fieldName: 'employer',
    },
    {
      title: t('economy:rejected-invoices-tab.table-head.file-name'),
      sortDirection,
      fieldName: 'fileName',
      handleSortDirectionChange: () => {
        onApplySorting('fileName');
      },
    },
    {
      title: t('economy:rejected-invoices-tab.table-head.insurance-provider'),
      fieldName: 'insuranceProvider',
    },
    {
      title: t('economy:rejected-invoices-tab.table-head.account-to'),
      fieldName: 'accountNumberTo',
    },
    {
      title: t('economy:rejected-invoices-tab.table-head.invoice-number'),
      sortDirection,
      fieldName: 'invoiceNumber',
      handleSortDirectionChange: () => {
        onApplySorting('invoiceNumber');
      },
    },
    {
      title: t('economy:rejected-invoices-tab.table-head.amount'),
      sortDirection,
      fieldName: 'totalAmount',
      handleSortDirectionChange: () => {
        onApplySorting('totalAmount');
      },
    },
    {
      title: t('economy:rejected-invoices-tab.table-head.invoice-type-code'),
      sortDirection,
      fieldName: 'invoiceTypeCode',
      handleSortDirectionChange: () => {
        onApplySorting('invoiceTypeCode');
      },
    },
    {
      title: t('economy:rejected-invoices-tab.table-head.invoice-due-date'),
      sortDirection,
      fieldName: 'invoiceDueDate',
      handleSortDirectionChange: () => {
        onApplySorting('invoiceDueDate');
      },
    },
    {
      title: t('economy:rejected-invoices-tab.table-head.invoice-created-date'),
      sortDirection,
      fieldName: 'createdDate',
      handleSortDirectionChange: () => {
        onApplySorting('createdDate');
      },
    },
  ];
  return (
    <TableRow>
      {tableHeads.map((head) => {
        return (
          <Th
            compressed
            includeSortButtons={head.sortDirection != undefined}
            sortDirection={head.fieldName === sortField ? sortDirection : undefined}
            key={`RejectedInvoicesTableHeads-${head.fieldName}`}
            handleSortDirectionChange={head.handleSortDirectionChange}
          >
            {head.title}
          </Th>
        );
      })}
    </TableRow>
  );
};

export default RejectedInvoicesTableHeads;
