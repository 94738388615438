import { SearchRejectedInvoicesFilters } from 'src/types/invoice/SearchRejectedInvoicesFilters';

export const getDefaultRejectedInvoicesFilter = (): SearchRejectedInvoicesFilters => {
  return {
    rejectedBy: [],
    createdDate: {
      from: { date: '', time: '' },
      to: { date: '', time: '' },
    },
    invoiceStatus: ['REJECTED'],
  };
};

export const validateRejectionDetails = (rejectionDetails) => {
  return rejectionDetails.some((detail) =>
    [
      'ROWS_SUM_NOT_EQUAL_TO_TOTAL_AMOUNT',
      'DUPLICATE_INVOICE',
      'INVALID_INVOICE_HEADER_PERIOD',
      'INVALID_INVOICE_ROW_PERIOD',
    ].includes(detail.rejectionCause),
  );
};
