import React, { useState } from 'react';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { SEARCH_INVOICES } from 'src/graphql/schema/query/SearchInvoices';
import { InternalSearchInvoicesPage } from 'src/types/Invoice';
import Invoices from './Invoices';
import './invoices-tab.scss';
import { CommonSearchInput } from 'src/types/common/CommonSearchInput';
import { InvoicesTableSortField } from './InvoicesTable/InvoicesTableSortField';
import { SortDirection } from 'fgirot-k2-ui-components';
import { SearchInvoicesFilter } from 'src/types/invoice/SearchInvoicesFilter';
import { getDefaultInvoicesFilters } from './utils/invoiceUtils';

const InvoicesTab = () => {
  const [invoicesFilter, setInvoicesFilter] = useState<SearchInvoicesFilter>(getDefaultInvoicesFilters());

  const [searchInput, setSearchInput] = useState<string>('');
  const [pageNumber, setPageNumber] = useState(1);
  const queryPageNumber = pageNumber - 1;
  const pageSize = 25;
  const handleOnPageChange = (nextPageNumber: number) => {
    setPageNumber(nextPageNumber);
  };

  const [sorting, setSorting] = useState<{ property?: InvoicesTableSortField; direction: SortDirection }>({
    property: 'paymentDueDate',
    direction: 'DOWN',
  });
  const handleTextSearch = (searchInput: string) => {
    setSearchInput(searchInput);
  };

  const handleSortingChange = (property: InvoicesTableSortField, sortDirection: SortDirection) => {
    setSorting({ property: property, direction: sortDirection });
  };

  const handleApplyFilters = (filters: SearchInvoicesFilter) => {
    setInvoicesFilter(filters);
  };

  const request: CommonSearchInput = {
    paging: {
      pageNumber: queryPageNumber,
      pageSize: pageSize,
    },
    sorting: sorting && [
      { property: sorting.property.toString(), direction: sorting.direction === 'UP' ? 'ASC' : 'DESC' },
    ],
    searchInputs: [
      searchInput?.length > 0 && {
        properties: ['legalName'],
        value: searchInput,
        exactMatch: false,
      },
    ].filter((search) => search && search.value != ''),
    filters: [
      {
        property: 'paymentServiceProvider',
        values: invoicesFilter?.paymentServiceProvider.length ? invoicesFilter.paymentServiceProvider : null,
        condition: 'IN',
      },
      {
        property: 'paymentDueDate',
        values: invoicesFilter?.paymentDueDate?.fromDate,
        condition: 'GE',
      },
      {
        property: 'paymentDueDate',
        values: invoicesFilter?.paymentDueDate?.toDate,
        condition: 'LE',
      },
      {
        property: 'bank',
        values: invoicesFilter?.bank.length ? invoicesFilter.bank : null,
        condition: 'IN',
      },
    ].filter((filter) => filter.values && (Array.isArray(filter.values) ? filter.values.length > 0 : true)),
  };

  return (
    <QueryWrapper
      query={SEARCH_INVOICES}
      options={{
        fetchPolicy: 'no-cache',
        variables: {
          request: request,
        },
      }}
    >
      {({ internalSearchInvoices, paging }: InternalSearchInvoicesPage) => (
        <Invoices
          invoices={internalSearchInvoices}
          paging={paging}
          pageNumber={pageNumber}
          pageSize={pageSize}
          sortProperty={sorting?.property}
          sortDirection={sorting.direction}
          searchInvoicesFilter={invoicesFilter}
          searchTextInput={searchInput}
          onTextSearch={handleTextSearch}
          onApplyFilters={handleApplyFilters}
          onApplySorting={handleSortingChange}
          onPageChange={handleOnPageChange}
        />
      )}
    </QueryWrapper>
  );
};

export default InvoicesTab;
