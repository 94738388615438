import { Payment } from 'src/types/Payment';
import { PaymentsFilter } from 'src/types/PaymentsFilter';
import { PaymentsTableSortField } from '../PaymentsTable/PaymentsTableSortField';
import { TableHeadProps } from 'src/types/TableHeadProps';
import { useTranslation } from 'react-i18next';

export const defaultPaymentsFilter = (): PaymentsFilter => {
  return {
    paymentCreatedDate: {
      from: { date: '', time: '' },
      to: { date: '', time: '' },
    },
    paymentDueDate: {
      fromDate: '',
      toDate: '',
    },
    paymentStatuses: ['PAYMENT_REQUEST_CREATED', 'PAYMENT_FAILED'],
    banks: [],
    paymentServiceProviders: [],
    customerId: '',
    employerId: '',
  };
};

export const groupPaymentByCustomer = (payments: Payment[]): Map<string, Payment[]> => {
  return payments.reduce((map, payment) => {
    const grouped = map.get(payment.customerId);
    if (grouped) {
      grouped.push(payment);
    } else {
      map.set(payment.customerId, [payment]);
    }
    return map;
  }, new Map<string, Payment[]>());
};

export const groupPaymentByEmployer = (payments: Payment[]): Map<string, Payment[]> => {
  return payments.reduce((map, payment) => {
    const grouped = map.get(payment.employer.id);
    if (grouped) {
      grouped.push(payment);
    } else {
      map.set(payment.employer.id, [payment]);
    }
    return map;
  }, new Map<string, Payment[]>());
};

export const getPaymentTableHeads = (
  onSortFieldChange: (sortField: PaymentsTableSortField) => void,
): TableHeadProps[] => {
  const { t } = useTranslation();
  return [
    {
      title: t(''),
      fieldName: '',
      includeSortButtons: false,
      excludeColumn: true,
    },
    {
      title: t('economy:payments-tab.table-head.employer'),
      fieldName: 'employer',
      excludeColumn: true,
    },
    {
      title: t('economy:payments-tab.table-head.insurance-provider'),
      fieldName: 'insuranceProvider',
      excludeColumn: true,
    },
    {
      title: t('economy:payments-tab.table-head.invoice-number'),
      fieldName: 'invoiceNumber',
      excludeColumn: true,
    },
    {
      title: t('economy:payments-tab.table-head.amount'),
      includeSortButtons: true,
      fieldName: 'amount',
      handleSortDirectionChange: () => {
        onSortFieldChange('amount');
      },
      excludeColumn: true,
    },
    {
      title: t('economy:payments-tab.table-head.payment-status'),
      fieldName: 'paymentStatus',
      excludeColumn: true,
    },
    {
      title: t('economy:payments-tab.table-head.payment-due-date'),
      fieldName: 'dueDate',
      includeSortButtons: true,
      handleSortDirectionChange: () => {
        onSortFieldChange('dueDate');
      },
      excludeColumn: true,
    },
    {
      title: t('economy:payments-tab.table-head.payment-created-date'),
      includeSortButtons: true,
      fieldName: 'createdDate',
      handleSortDirectionChange: () => {
        onSortFieldChange('createdDate');
      },
      excludeColumn: true,
    },
    {
      title: t('economy:payments-tab.table-head.time-stamp'),
      fieldName: 'timeStamp',
      excludeColumn: true,
    },
    {
      title: t('economy:payments-tab.table-head.payment-service-provider'),
      fieldName: 'paymentServiceProvider',
      includeSortButtons: true,
      handleSortDirectionChange: () => {
        onSortFieldChange('paymentServiceProvider');
      },
      excludeColumn: true,
    },
    {
      title: t('economy:payments-tab.table-head.bank'),
      fieldName: 'bank',
      includeSortButtons: true,
      handleSortDirectionChange: () => {
        onSortFieldChange('bank');
      },
      excludeColumn: true,
    },
    {
      title: t('economy:payments-tab.table-head.account-from'),
      fieldName: 'accountFrom',
      excludeColumn: true,
    },
    {
      title: t('economy:payments-tab.table-head.account-to'),
      fieldName: 'accountTo',
    },
    {
      title: t('economy:payments-tab.table-head.description'),
      fieldName: 'description',
    },
    {
      title: t('economy:payments-tab.table-head.payment-error-code'),
      fieldName: 'paymentErrorCode',
    },
    {
      title: t('economy:payments-tab.table-head.payment-reference'),
      fieldName: 'paymentId',
    },
  ];
};
