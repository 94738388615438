import { Employer } from './Employer';
import { PageableResponse } from './PageableResponse';
import { Invoice } from './Invoice';

export interface Payment {
  id: string;
  paymentDueDate: string;
  paymentServiceProvider: string;
  bank: string;
  customerId: string;
  employer: Employer;
  paymentCreatedDate: string;
  amount: number;
  paymentStatus: PaymentStatus;
  errorCode: string;
  invoice: Invoice;
  accountFrom: string;
  accountTo: string;
  description: string;
  numberOfNotes: number;
  paymentId: string;
}

export interface SearchPaymentsPage extends PageableResponse {
  internalSearchPayments: Payment[];
}

export type PaymentStatus = 'PAYMENT_REQUEST_CREATED' | 'PAYMENT_FAILED' | 'PAYMENT_COMPLETED';
export const paymentStatuses: PaymentStatus[] = ['PAYMENT_REQUEST_CREATED', 'PAYMENT_FAILED', 'PAYMENT_COMPLETED'];
