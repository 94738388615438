import React, { useState } from 'react';
import { PaymentsFilter } from 'src/types/PaymentsFilter';
import { defaultPaymentsFilter } from './utils/paymentUtils';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { SEARCH_PAYMENTS } from 'src/graphql/schema/query/SearchPayments';
import { SearchPaymentsPage } from 'src/types/Payment';
import Payments from './Payments';
import './payments-tab.scss';
import { PaymentsTableSortField } from './PaymentsTable/PaymentsTableSortField';
import { SortDirection } from 'fgirot-k2-ui-components';
import { CommonSearchInput } from 'src/types/common/CommonSearchInput';
import { Sort } from 'src/types/common/Sort';
import { DateTime } from 'luxon';

const PaymentsTab = () => {
  const [paymentsFilter, setPaymentsFilter] = useState<PaymentsFilter>(defaultPaymentsFilter());
  const [sorting, setSorting] = useState<{ property?: PaymentsTableSortField; direction: SortDirection }>({
    property: 'createdDate',
    direction: 'DOWN',
  });
  const [pageNumber, setPageNumber] = useState(1);
  const queryPageNumber = pageNumber - 1;
  const pageSize = 25;
  const handleOnPageChange = (nextPageNumber: number) => {
    setPageNumber(nextPageNumber);
  };

  const handleSortingChange = (property: PaymentsTableSortField, sortDirection: SortDirection) => {
    setSorting({ property: property, direction: sortDirection });
  };

  const handleApplyFilters = (filters: PaymentsFilter) => {
    setPaymentsFilter(filters);
  };

  const getCreatedDate = (date: string, time: string, defaultTime: string) => {
    if (date == undefined || date == '') {
      return undefined;
    }
    const t = time != undefined && time != '' ? time : defaultTime;

    const dtInUtc = DateTime.fromISO(date + 'T' + t, { zone: 'Europe/Stockholm' })
      .setLocale('sv-SE')
      .setZone('utc')
      .toISO({ includeOffset: false });

    return dtInUtc;
  };

  const request: CommonSearchInput = {
    paging: {
      pageNumber: queryPageNumber,
      pageSize: pageSize,
    },
    sorting: sorting && [
      { property: sorting.property.toString(), direction: sorting.direction == 'UP' ? 'ASC' : 'DESC' },
    ],
    filters: [
      {
        property: 'createdDate',
        values: getCreatedDate(
          paymentsFilter?.paymentCreatedDate?.from?.date,
          paymentsFilter?.paymentCreatedDate?.from?.time,
          '00:00',
        ),
        condition: 'GE',
      },
      {
        property: 'createdDate',
        values: getCreatedDate(
          paymentsFilter?.paymentCreatedDate?.to?.date,
          paymentsFilter?.paymentCreatedDate?.to?.time,
          '23:59',
        ),
        condition: 'LE',
      },
      {
        property: 'status',
        values: paymentsFilter?.paymentStatuses,
        condition: 'IN',
      },
      {
        property: 'bank',
        values: paymentsFilter?.banks,
        condition: 'IN',
      },
      {
        property: 'paymentServiceProvider',
        values: paymentsFilter?.paymentServiceProviders,
        condition: 'IN',
      },
      {
        property: 'dueDate',
        values: paymentsFilter?.paymentDueDate?.fromDate,
        condition: 'GE',
      },
      {
        property: 'dueDate',
        values: paymentsFilter?.paymentDueDate?.toDate,
        condition: 'LE',
      },
      {
        property: 'customerId',
        values: paymentsFilter?.customerId,
        condition: 'IN',
      },
      {
        property: 'employerId',
        values: paymentsFilter?.employerId,
        condition: 'IN',
      },
    ].filter((filter) => filter.values && (Array.isArray(filter.values) ? filter.values.length > 0 : true)),
  };

  return (
    <QueryWrapper
      query={SEARCH_PAYMENTS}
      options={{
        fetchPolicy: 'no-cache',
        variables: {
          request: request,
        },
      }}
    >
      {({ internalSearchPayments, paging }: SearchPaymentsPage) => (
        <Payments
          payments={internalSearchPayments}
          paging={paging}
          pageNumber={pageNumber}
          pageSize={pageSize}
          paymentsFilter={paymentsFilter}
          sortProperty={sorting?.property}
          sortDirection={sorting.direction}
          onApplySorting={handleSortingChange}
          onApplyFilters={handleApplyFilters}
          onPageChange={handleOnPageChange}
        />
      )}
    </QueryWrapper>
  );
};

export default PaymentsTab;
