import React, { FC, useMemo, useState } from 'react';
import { Button, Icon, Pagination, SortDirection } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { Invoice, InvoiceStatusEnum } from 'src/types/Invoice';
import { getTotalPages } from 'src/util/Pagination/Pagination';
import { Paging } from 'src/types/PageableResponse';
import RejectedInvoicesFilterDetails from '../RejectedInvoicesFilterDetails';
import RejectedInvoicesFiltersModal from '../RejectedInvoicesFiltersModal';
import RejectedInvoicesTable from '../RejectedInvoicesTable';
import { usePatchInvoiceHeader } from 'src/service/PatchInvoiceHeader';
import RejectedInvoicesConfirmModal from '../RejectedInvoicesConfirmModal';
import { groupInvoiceByCustomer, groupInvoiceByEmployer } from 'src/components/Economy/InvoicesTab/utils/invoiceUtils';
import { useCreateResendInvoices } from 'src/service/CreateResendInvoices';
import { useApolloClient } from '@apollo/client';
import { GET_REJECTION_DETAILS } from 'src/graphql/schema/query/GetRejectionDetails';
import { validateRejectionDetails } from '../utils/rejectedInvoiceUtils';
import { RejectedInvoicesTableSortField } from '../RejectedInvoicesTable/RejectedInvoicesTableSortField';
import { SearchRejectedInvoicesFilters } from 'src/types/invoice/SearchRejectedInvoicesFilters';

interface RejectedInvoicesProps {
  invoices: Invoice[];
  paging: Paging;
  pageNumber: number;
  pageSize: number;
  sortProperty?: RejectedInvoicesTableSortField;
  sortDirection: SortDirection;
  searchRejectedInvoicesFilter: SearchRejectedInvoicesFilters;
  onApplyFilters: (filters: SearchRejectedInvoicesFilters) => void;
  onApplySorting: (property: string, sortDirection: SortDirection) => void;
  onPageChange: (pageSize: number) => void;
}

const RejectedInvoices: FC<RejectedInvoicesProps> = ({
  invoices,
  paging,
  pageNumber,
  pageSize,
  sortProperty,
  sortDirection,
  searchRejectedInvoicesFilter,
  onApplyFilters,
  onApplySorting,
  onPageChange,
}) => {
  const { t } = useTranslation();
  const totalPages = useMemo(() => getTotalPages(paging.totalNumberOfElements, pageSize), [paging, pageSize]);
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<string | undefined>();
  const [checked, setChecked] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const client = useApolloClient();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedInvoiceIds, setSelectedInvoiceIds] = useState<string[]>([]);
  const [handledInvoice, setHandledInvoice] = useState<string[]>([]);
  const createResendInvoices = useCreateResendInvoices();

  const handleCloseModal = () => setIsFiltersModalOpen(false);
  const handleModalOpen = () => setIsFiltersModalOpen(true);
  const handleDrawerOpen = (id: string) => setSelectedInvoiceId(id);
  const patchInvoiceHeader = usePatchInvoiceHeader();
  const [isResendButtonDisabled, setIsResendButtonDisabled] = useState(true);

  const toggleChecked = (id: string) => {
    const newChecked = checked.includes(id) ? checked.filter((c) => c !== id) : [...checked, id];
    setChecked(newChecked);
    validateRejectedInvoices(newChecked, invoices).then(({ invalidInvoices }) => {
      setIsResendButtonDisabled(invalidInvoices.length > 0 || isLoading);
    });
  };

  const toggleAll = () => {
    const newChecked =
      checked.length === invoices.length && invoices.every((invoice) => checked.includes(invoice.id))
        ? []
        : invoices.map((invoice) => invoice.id);
    setChecked(newChecked);
    validateRejectedInvoices(newChecked, invoices).then(({ invalidInvoices }) => {
      setIsResendButtonDisabled(invalidInvoices.length > 0 || isLoading);
    });
  };

  const handleHandleClick = () => {
    if (checked.length > 0) {
      setSelectedInvoiceIds(checked);
      setIsModalOpen(true);
    }
  };
  const handleCreateResendInvoice = async () => {
    if (checked.length === 0) return;

    setIsLoading(true);

    const { validInvoices } = await validateRejectedInvoices(checked, invoices);

    if (validInvoices.length > 0) {
      const groupedByCustomer = groupInvoiceByCustomer(validInvoices);
      groupedByCustomer.forEach((customerGroup) => {
        const groupedByEmployer = groupInvoiceByEmployer(customerGroup);
        groupedByEmployer.forEach((invoices) => {
          const invoicesToSend = invoices.map((invoice) => invoice.id);

          createResendInvoices({ invoiceIds: invoicesToSend }, invoices[0].employer.id, invoices[0].customerId);
        });
      });
      setChecked([]);
    }

    setIsLoading(false);
  };

  const patchInvoiceHeaderStatus = (status: string, note: string) => {
    const invoicesToPatch = checked.map((invoiceId) => ({
      invoiceHeaderId: invoiceId,
      invoiceStatus: status,
      handledDetails: [{ note }],
    }));

    const groupedByEmployer = invoicesToPatch.reduce((acc, invoice) => {
      const employerId = invoices.find((i) => i.id === invoice.invoiceHeaderId)?.employer.id;
      if (!acc[employerId]) acc[employerId] = [];
      acc[employerId].push(invoice);
      return acc;
    }, {} as Record<string, typeof invoicesToPatch>);

    Object.entries(groupedByEmployer).forEach(([employerId, invoicesForEmployer]) => {
      patchInvoiceHeader(invoicesForEmployer, employerId);
    });

    if (status === InvoiceStatusEnum.HANDLED) {
      setHandledInvoice((prev) => [...prev, ...checked]);
    } else {
      setHandledInvoice((prev) => prev.filter((invoiceId) => !checked.includes(invoiceId)));
    }

    setChecked([]);
  };

  const validateRejectedInvoices = async (checkedIds: string[], invoicesToValidate: Invoice[]) => {
    const validInvoices: Invoice[] = [];
    const invalidInvoices: Invoice[] = [];

    const fetchDetailsPromises = checkedIds.map(async (invoiceId) => {
      const invoice = invoicesToValidate.find((inv) => inv.id === invoiceId);
      if (!invoice) return;

      if (!invoice.fileName?.endsWith('.xml')) {
        invalidInvoices.push(invoice);
        return;
      }

      const result = await client.query({ query: GET_REJECTION_DETAILS, variables: { invoiceHeaderId: invoiceId } });
      validateRejectionDetails(result.data.internalRejectionDetails)
        ? invalidInvoices.push(invoice)
        : validInvoices.push(invoice);
    });
    await Promise.all(fetchDetailsPromises);
    return { validInvoices, invalidInvoices };
  };

  const handleConfirm = async (note: string) => {
    patchInvoiceHeaderStatus(InvoiceStatusEnum.HANDLED, note);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <div className="rejected-invoices-tab">
        <RejectedInvoicesFilterDetails filters={searchRejectedInvoicesFilter} applyFilters={onApplyFilters} />
        <div className="rejected-invoices-tab__mark-button">
          <Button type="primary" label={t('economy:rejected-invoices-tab.buttons.mark-button')} onClick={toggleAll} />
        </div>
        <div className="rejected-invoices-tab__table-buttons">
          <Button
            type="primary"
            label={t('economy:rejected-invoices-tab.buttons.handle-button')}
            onClick={handleHandleClick}
            disabled={checked.length === 0}
          />
          <Button
            type="primary"
            label={t('economy:rejected-invoices-tab.buttons.resend-button')}
            onClick={handleCreateResendInvoice}
            disabled={isResendButtonDisabled}
          />
        </div>
        <RejectedInvoicesTable
          invoices={invoices}
          onRowClick={handleDrawerOpen}
          checked={checked}
          onChange={toggleChecked}
          applySorting={onApplySorting}
          sortingProperty={sortProperty}
          sortDirection={sortDirection}
        />
        {totalPages > 1 && <Pagination currentPage={pageNumber} onPageChange={onPageChange} totalPages={totalPages} />}
        {isFiltersModalOpen && (
          <RejectedInvoicesFiltersModal
            open={isFiltersModalOpen}
            onClose={handleCloseModal}
            onApplyFilters={onApplyFilters}
            filters={searchRejectedInvoicesFilter}
          />
        )}
        {isModalOpen && (
          <RejectedInvoicesConfirmModal open={isModalOpen} onConfirm={handleConfirm} onClose={handleCancel} />
        )}

        <div className="rejected-invoices-tab__filter-button">
          <Button
            type="floating-action-button"
            icon={<Icon type="Sliders" stroke="white" />}
            label={t('common:filter')}
            onClick={handleModalOpen}
            data-cy="filter-button"
          />
        </div>
      </div>
    </>
  );
};

export default RejectedInvoices;
