import { SortDirection, TableBody, TableHead } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import TableBase from 'src/components/Common/TableBase';
import InvoicesTableRow from './InvoicesTableRow';
import { InvoicesTableSortField } from './InvoicesTableSortField';
import InvoicesTableHeads from './InvoicesTableHeads';
import { Invoice } from 'src/types/Invoice';
import './invoices-table.scss';

interface InvoicesTableProps {
  invoices: Invoice[];
  sortingProperty?: InvoicesTableSortField;
  sortDirection?: SortDirection;
  checked: string[];
  onChange: (id: string) => void;
  applySorting(property: string, direction: SortDirection);
}

const InvoicesTable: FC<InvoicesTableProps> = ({
  invoices,
  sortingProperty,
  sortDirection,
  checked,
  onChange,
  applySorting,
}) => {
  const handleSort = (property: InvoicesTableSortField) => {
    if (property == sortingProperty) {
      applySorting(property, sortDirection == 'UP' ? 'DOWN' : 'UP');
    } else {
      applySorting(property, sortDirection);
    }
  };

  return (
    <div className="invoices-table">
      <TableBase compressed>
        <TableHead filled>
          <InvoicesTableHeads sortField={sortingProperty} sortDirection={sortDirection} onApplySorting={handleSort} />
        </TableHead>
        <TableBody>
          {invoices?.map((invoice) => (
            <InvoicesTableRow
              key={`InvoiceTableRow-${invoice.id}`}
              invoice={invoice}
              onChange={() => onChange(invoice.id)}
              checked={checked.includes(invoice.id) ? true : false}
            />
          ))}
        </TableBody>
      </TableBase>
    </div>
  );
};

export default InvoicesTable;
