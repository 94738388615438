import React, { useState } from 'react';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { SearchRejectedInvoicesPage } from 'src/types/Invoice';
import { SEARCH_REJECTED_INVOICES } from 'src/graphql/schema/query/SearchRejectedInvoices';
import RejectedInvoices from './RejectedInvoices';
import './rejected-invoices-tab.scss';
import { CommonSearchInput } from 'src/types/common/CommonSearchInput';
import { DateTime } from 'luxon';
import { SortDirection } from 'fgirot-k2-ui-components';
import { RejectedInvoicesTableSortField } from './RejectedInvoicesTable/RejectedInvoicesTableSortField';
import { SearchRejectedInvoicesFilters } from 'src/types/invoice/SearchRejectedInvoicesFilters';
import { getDefaultRejectedInvoicesFilter } from './utils/rejectedInvoiceUtils';

const RejectedInvoicesTab = () => {
  const [invoicesFilter, setInvoicesFilter] = useState<SearchRejectedInvoicesFilters>(
    getDefaultRejectedInvoicesFilter(),
  );
  const [pageNumber, setPageNumber] = useState(1);
  const queryPageNumber = pageNumber - 1;
  const pageSize = 25;

  const [sort, setSort] = useState<{ property?: RejectedInvoicesTableSortField; direction: SortDirection }>({
    property: 'totalAmount',
    direction: 'DOWN',
  });

  const handleOnPageChange = (nextPageNumber: number) => {
    setPageNumber(nextPageNumber);
  };

  const handleApplyFilters = (filters: SearchRejectedInvoicesFilters) => {
    setInvoicesFilter(filters);
  };

  const handleSortingChange = (property: RejectedInvoicesTableSortField, sortDirection: SortDirection) => {
    setSort({ property: property, direction: sortDirection });
  };

  const getCreatedDate = (date: string, time: string, defaultTime: string) => {
    if (date == undefined || date == '') {
      return undefined;
    }
    const t = time != undefined && time != '' ? time : defaultTime;

    const dateInUtc = DateTime.fromISO(date + 'T' + t, { zone: 'Europe/Stockholm' })
      .setLocale('sv-SE')
      .setZone('utc')
      .toISO({ includeOffset: false });

    return dateInUtc;
  };

  const request: CommonSearchInput = {
    paging: {
      pageNumber: queryPageNumber,
      pageSize: pageSize,
    },
    sorting: sort && [{ property: sort.property.toString(), direction: sort.direction == 'UP' ? 'ASC' : 'DESC' }],
    filters: [
      {
        property: 'rejectedBy',
        values: invoicesFilter?.rejectedBy,
        condition: 'IN',
      },
      {
        property: 'createdDate',
        values: getCreatedDate(
          invoicesFilter?.createdDate?.from?.date,
          invoicesFilter?.createdDate?.from?.time,
          '00:00',
        ),
        condition: 'GE',
      },
      {
        property: 'createdDate',
        values: getCreatedDate(invoicesFilter?.createdDate?.to?.date, invoicesFilter?.createdDate?.to?.time, '23:59'),
        condition: 'LE',
      },
    ].filter((filter) => filter.values && filter.values.length > 0),
  };

  return (
    <QueryWrapper
      query={SEARCH_REJECTED_INVOICES}
      options={{
        fetchPolicy: 'no-cache',
        variables: {
          request: request,
        },
      }}
    >
      {({ internalSearchRejectedInvoices, paging }: SearchRejectedInvoicesPage) => (
        <RejectedInvoices
          invoices={internalSearchRejectedInvoices}
          paging={paging}
          pageNumber={pageNumber}
          pageSize={pageSize}
          sortProperty={sort?.property}
          sortDirection={sort.direction}
          searchRejectedInvoicesFilter={invoicesFilter}
          onApplyFilters={handleApplyFilters}
          onApplySorting={handleSortingChange}
          onPageChange={handleOnPageChange}
        />
      )}
    </QueryWrapper>
  );
};

export default RejectedInvoicesTab;
