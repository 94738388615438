import { gql } from '@apollo/client';

export const SEARCH_PAYMENTS = gql`
  query InternalSearchPayments($request: CommonSearchInput) {
    internalSearchPayments(request: $request) {
      paging {
        hasNext
        totalNumberOfElements
      }
      internalSearchPayments {
        id
        paymentServiceProvider
        bank
        customerId
        employer {
          id
          legalName
        }
        paymentCreatedDate
        amount
        paymentStatus
        errorCode
        invoice {
          id
          insuranceProvider {
            id
            organisationNumber
            legalName
          }
          invoiceNumber
        }
        paymentDueDate
        accountFrom
        accountTo
        description
        numberOfNotes
        paymentId
      }
    }
  }
`;
