import { Button, Card, CardAction, Icon, Modal, Option, Select } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { FC, useState } from 'react';
import { createOptions } from 'src/util/OptionUtil';
import UpdateDatePicker from 'src/components/Common/UpdateDatePicker';
import { rejectedBy, RejectedBy } from 'src/types/RejectionDetail';
import './rejected-invoices-filters-modal.scss';
import { SearchRejectedInvoicesFilters } from 'src/types/invoice/SearchRejectedInvoicesFilters';

interface RejectedInvoicesFiltersModalProps {
  open: boolean;
  filters: SearchRejectedInvoicesFilters;
  onClose: () => void;
  onApplyFilters: (newFilters: SearchRejectedInvoicesFilters) => void;
}

const RejectedInvoicesFiltersModal: FC<RejectedInvoicesFiltersModalProps> = ({
  open,
  filters,
  onClose,
  onApplyFilters,
}) => {
  const { t } = useTranslation();

  const [newFilters, setNewFilters] = useState<SearchRejectedInvoicesFilters>({ ...filters });

  const handleRejectedByFilterChange = (options: Option<string>[]) => {
    setNewFilters({
      ...newFilters,
      rejectedBy: options.length > 0 ? options.map((option) => option.value) : null,
    });
  };

  const handleFromCreatedDateFilterChange = (__field: string, value: string) => {
    setNewFilters({
      ...newFilters,
      createdDate: {
        from: { ...newFilters.createdDate.from, date: value },
        to: newFilters.createdDate.to,
      },
    });
  };

  const handleToCreatedDateFilterChange = (__field: string, value: string) => {
    setNewFilters({
      ...newFilters,
      createdDate: {
        from: newFilters.createdDate.from,
        to: { ...newFilters.createdDate.to, date: value },
      },
    });
  };

  const onApply = () => {
    onApplyFilters(newFilters);
    onClose();
  };

  const rejectedByOptions: Option<RejectedBy>[] = createOptions(rejectedBy, t, 'invoice:rejected-by');

  return (
    <Modal centered overlayBackground open={open} onClose={onClose}>
      <Card
        className="rejected-invoices-filters-modal"
        title={t('economy:rejected-invoices-modal.title')}
        icon={<Icon type="Sliders" className="rejected-invoices-filters-modal__icon" />}
        size="wide"
      >
        <div className="rejected-invoices-filters-modal__filters">
          <div className="rejected-invoices-filters-modal__select">
            <Select
              label={t('economy:rejected-invoices-modal.rejected-by-label')}
              placeholder={t('common:all')}
              selectAll
              options={rejectedByOptions}
              selected={rejectedByOptions.filter((option) => newFilters?.rejectedBy?.includes(option.value))}
              onChange={handleRejectedByFilterChange}
              small
              outlined
              multiple
            />
          </div>
          <div className="handled-invoices-filters-modal__date-time-interval">
            <div className="handled-invoices-filters-modal__row"></div>
          </div>

          <div className="handled-invoices-filters-modal__date-time-interval">
            <div className="handled-invoices-filters-modal__row">
              <div className="handled-invoices-filters-modal__date-section">
                <UpdateDatePicker
                  label={t('economy:handled-invoices-filters-modal.from-created-date-label')}
                  fieldValue={newFilters?.createdDate?.from?.date?.toString()}
                  fieldKey="createdDate"
                  onBlur={handleFromCreatedDateFilterChange}
                />
                <UpdateDatePicker
                  label={t('economy:handled-invoices-filters-modal.to-created-date-label')}
                  fieldValue={newFilters?.createdDate?.to?.date?.toString()}
                  fieldKey="createdDate"
                  onBlur={handleToCreatedDateFilterChange}
                />
              </div>
            </div>
          </div>
        </div>
        <CardAction>
          <Button label={t('common:cancel')} type="link" onClick={onClose} />
          <Button label={t('economy:rejected-invoices-modal.use-button')} onClick={onApply} />
        </CardAction>
      </Card>
    </Modal>
  );
};

export default RejectedInvoicesFiltersModal;
