import { Button, Typography } from 'fgirot-k2-ui-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FilterButton from 'src/components/Common/FilterButton';
import './invoices-filter-details.scss';
import { SearchInvoicesFilter } from 'src/types/invoice/SearchInvoicesFilter';

interface InvoicesFilterDetailsProps {
  filters: SearchInvoicesFilter;
  applyFilters: (filters: SearchInvoicesFilter) => void;
}

export const InvoicesFilterDetails: FC<InvoicesFilterDetailsProps> = ({ filters, applyFilters }) => {
  const { t } = useTranslation();
  const filtersApplied =
    filters?.paymentDueDate?.fromDate.length > 0 ||
    filters?.paymentDueDate?.toDate.length > 0 ||
    filters?.bank?.length > 0 ||
    filters?.paymentServiceProvider?.length > 0;

  const removeFromPaymentDueDateFilter = () => {
    applyFilters({
      ...filters,
      paymentDueDate: { fromDate: '', toDate: filters.paymentDueDate?.toDate },
    });
  };

  const removeToPaymentDueDateFilter = () => {
    applyFilters({
      ...filters,
      paymentDueDate: { fromDate: filters.paymentDueDate?.fromDate, toDate: '' },
    });
  };

  const removeBankFilter = () => {
    applyFilters({
      ...filters,
      bank: [],
    });
  };

  const removePaymentServiceProviderFilter = () => {
    applyFilters({
      ...filters,
      paymentServiceProvider: [],
    });
  };

  const removeAllFilters = () => {
    applyFilters({
      paymentDueDate: {
        fromDate: '',
        toDate: '',
      },
      bank: [],
      paymentServiceProvider: [],
      searchInput: '',
    });
  };

  return (
    <div className="invoices-filter-details">
      <Typography variant="caption" bold>
        Filter:
      </Typography>
      {!filtersApplied && (
        <Typography variant="caption" italic>
          Inga filter
        </Typography>
      )}
      {filtersApplied && (
        <>
          {filters?.paymentDueDate?.fromDate.length > 0 && (
            <FilterButton
              label={
                filters?.paymentDueDate.fromDate + ' ' + t('economy:search-invoices-filters.from-payment-due-date')
              }
              onClick={removeFromPaymentDueDateFilter}
            />
          )}
          {filters?.paymentDueDate?.toDate.length > 0 && (
            <FilterButton
              label={filters?.paymentDueDate.toDate + ' ' + t('economy:search-invoices-filters.to-payment-due-date')}
              onClick={removeToPaymentDueDateFilter}
            />
          )}
          {filters?.bank?.length > 0 && (
            <FilterButton
              label={filters?.bank.length + ' ' + t('economy:search-invoices-filters:bank')}
              onClick={removeBankFilter}
            />
          )}
          {filters?.paymentServiceProvider?.length > 0 && (
            <FilterButton
              label={
                filters?.paymentServiceProvider.length +
                ' ' +
                t('economy:search-invoices-filters:payment-service-provider')
              }
              onClick={removePaymentServiceProviderFilter}
            />
          )}
          {
            <Button
              type="warning"
              size="small"
              label={t('economy:search-handled-invoices-filters.remove-all')}
              onClick={removeAllFilters}
            />
          }
        </>
      )}
    </div>
  );
};

export default InvoicesFilterDetails;
