import { CreatePaymentNoteInput } from 'src/types/request/CreatePaymentNoteInput';
import { CREATE_PAYMENT_NOTE } from 'src/graphql/schema/mutation/CreatePaymentNote';
import { GET_PAYMENT_NOTES } from 'src/graphql/schema/query/GetPaymentNotes';
import { client } from 'src/graphql';
import { SEARCH_PAYMENTS } from 'src/graphql/schema/query/SearchPayments';

export const useCreatePaymentNote = async (
  paymentId: string,
  request: CreatePaymentNoteInput,
  employerId: string,
  customerId: string,
): Promise<void> => {
  try {
    await client.mutate({
      mutation: CREATE_PAYMENT_NOTE,
      variables: { paymentId, request },
      context: { employerId, customerId },
    });

    client.refetchQueries({
      include: [SEARCH_PAYMENTS, GET_PAYMENT_NOTES],
    });
  } catch (error) {
    console.error('Error creating payment note:', error);
    throw new Error('Error creating payment note');
  }
};
