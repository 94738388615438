import { Button, Card, CardAction, Icon, Modal, Option, Select } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { FC, useState } from 'react';
import { createOptions } from 'src/util/OptionUtil';
import './search-invoices-filters-modal.scss';
import UpdateDatePicker from 'src/components/Common/UpdateDatePicker';
import { PaymentServiceProviderType, paymentServiceProviderType } from 'src/types/PaymentServiceProviderType';
import { BankType, bankType } from 'src/types/BankType';
import { SearchInvoicesFilter } from 'src/types/invoice/SearchInvoicesFilter';

interface SearchInvoicesFiltersModalProps {
  open: boolean;
  filters: SearchInvoicesFilter;
  onClose: () => void;
  onApplyFilters: (newFilters: SearchInvoicesFilter) => void;
}

const SearchInvoicesFiltersModal: FC<SearchInvoicesFiltersModalProps> = ({
  open,
  filters,
  onClose,
  onApplyFilters,
}) => {
  const { t } = useTranslation();

  const [newFilters, setNewFilters] = useState<SearchInvoicesFilter>({ ...filters });

  const handleBankFilterChange = (options: Option<string>[]) => {
    setNewFilters({
      ...newFilters,
      bank: options.map((option) => option.value),
    });
  };

  const handlePaymentServiceProviderFilterChange = (options: Option<string>[]) => {
    setNewFilters({
      ...newFilters,
      paymentServiceProvider: options.map((option) => option.value),
    });
  };

  const handleFromPaymentDueDateFilterChange = (value: string) => {
    setNewFilters({
      ...newFilters,
      paymentDueDate: {
        fromDate: value,
        toDate: newFilters.paymentDueDate.toDate,
      },
    });
  };

  const handleToPaymentDueDateFilterChange = (value: string) => {
    setNewFilters({
      ...newFilters,
      paymentDueDate: {
        fromDate: newFilters.paymentDueDate.fromDate,
        toDate: value,
      },
    });
  };

  const onApply = () => {
    onApplyFilters(newFilters);
    onClose();
  };

  const bankOptions: Option<BankType>[] = createOptions(bankType, t, 'payment:bank');

  const paymentServiceProviderOptions: Option<PaymentServiceProviderType>[] = createOptions(
    paymentServiceProviderType,
    t,
    'payment:payment-service-provider',
  );

  return (
    <Modal centered overlayBackground open={open} onClose={onClose}>
      <Card
        className="search-invoices-filters-modal"
        title={t('economy:search-invoices-filters-modal.title')}
        icon={<Icon type="Sliders" className="search-invoices-filters-modal__icon" />}
        size="wide"
      >
        <div className="search-invoices-filters-modal__filters">
          <div className="search-invoices-filters-modal__select">
            <Select
              label={t('economy:search-invoices-filters-modal.bank-label')}
              placeholder={t('common:all')}
              selectAll
              options={bankOptions}
              selected={bankOptions.filter((option) => newFilters?.bank?.includes(option.value))}
              onChange={handleBankFilterChange}
              small
              outlined
              multiple
            />
          </div>
          <div className="search-invoices-filters-modal__select">
            <Select
              label={t('economy:search-invoices-filters-modal.payment-service-provider-label')}
              placeholder={t('common:all')}
              selectAll
              options={paymentServiceProviderOptions}
              selected={paymentServiceProviderOptions.filter((option) =>
                newFilters?.paymentServiceProvider?.includes(option.value),
              )}
              onChange={handlePaymentServiceProviderFilterChange}
              small
              outlined
              multiple
            />
          </div>
          <div className="search-invoices-filters-modal__date-section">
            <UpdateDatePicker
              label={t('economy:search-invoices-filters-modal.from-payment-due-date-label')}
              fieldValue={newFilters?.paymentDueDate?.fromDate ?? ''}
              fieldKey="paymentDueDate"
              onBlur={(_, value) => {
                handleFromPaymentDueDateFilterChange(value);
              }}
            />
          </div>

          <UpdateDatePicker
            label={t('economy:search-invoices-filters-modal.to-payment-due-date-label')}
            fieldValue={newFilters?.paymentDueDate?.toDate ?? ''}
            fieldKey="paymentDueDate"
            onBlur={(_, value) => {
              handleToPaymentDueDateFilterChange(value);
            }}
          />
        </div>
        <CardAction>
          <Button label={t('common:cancel')} type="link" onClick={onClose} />
          <Button label={t('economy:search-invoices-filters-modal.use-button')} onClick={onApply} />
        </CardAction>
      </Card>
    </Modal>
  );
};

export default SearchInvoicesFiltersModal;
