import { SortDirection, TableRow, Th } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TableHeadProps } from 'src/types/TableHeadProps';
import { InvoicesTableSortField } from './InvoicesTableSortField';

interface InvoicesTableHeadsProps {
  sortDirection: SortDirection;
  sortField: InvoicesTableSortField;
  onApplySorting(property: InvoicesTableSortField);
}

const InvoicesTableHeads: FC<InvoicesTableHeadsProps> = ({ sortDirection, sortField, onApplySorting }) => {
  const { t } = useTranslation();
  const tableHeads: TableHeadProps[] = [
    {
      title: t(''),
      fieldName: 'checkbox',
      includeSortButtons: false,
    },
    {
      title: t('economy:invoices-tab.table-head.employer'),
      fieldName: 'employer',
    },
    {
      title: t('economy:invoices-tab.table-head.insurance-provider'),
      fieldName: 'insuranceProvider',
    },
    {
      title: t('economy:invoices-tab.table-head.invoice-number'),
      sortDirection,
      includeSortButtons: true,
      fieldName: 'invoiceNumber',
      handleSortDirectionChange: () => {
        onApplySorting('invoiceNumber');
      },
    },
    {
      title: t('economy:invoices-tab.table-head.amount'),
      fieldName: 'totalAmount',
    },
    {
      title: t('economy:invoices-tab.table-head.invoice-status'),
      sortDirection,
      includeSortButtons: true,
      fieldName: 'invoiceStatus',
      handleSortDirectionChange: () => {
        onApplySorting('invoiceStatus');
      },
    },
    {
      title: t('economy:invoices-tab.table-head.invoice-due-date'),
      sortDirection,
      fieldName: 'dueDate',
      includeSortButtons: true,
      handleSortDirectionChange: () => {
        onApplySorting('invoiceDueDate');
      },
    },
    {
      title: t('economy:invoices-tab.table-head.payment-due-date'),
      sortDirection,
      includeSortButtons: true,
      fieldName: 'paymentDueDate',
      handleSortDirectionChange: () => {
        onApplySorting('paymentDueDate');
      },
    },
    {
      title: t('economy:invoices-tab.table-head.payment-service-provider'),
      fieldName: 'paymentServiceProvider',
    },
    {
      title: t('economy:invoices-tab.table-head.bank'),
      fieldName: 'bank',
    },
    {
      title: t('economy:invoices-tab.table-head.account-from'),
      fieldName: 'accountNumberFrom',
    },
    {
      title: t('economy:invoices-tab.table-head.account-to'),
      fieldName: 'accountNumberTo',
    },
  ];
  return (
    <TableRow>
      {tableHeads.map((head) => {
        return (
          <Th
            compressed
            includeSortButtons={head.includeSortButtons}
            sortDirection={head.fieldName === sortField ? sortDirection : undefined}
            key={`invoices-table-heads-${head.fieldName}`}
            handleSortDirectionChange={head.handleSortDirectionChange}
          >
            {head.title}
          </Th>
        );
      })}
    </TableRow>
  );
};

export default InvoicesTableHeads;
