import { Button, Typography } from 'fgirot-k2-ui-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FilterButton from 'src/components/Common/FilterButton';
import { SearchHandledInvoicesFilter } from 'src/types/invoice/SearchHandledInvoicesFilter';
import './handled-invoices-filter-details.scss';

interface HandledInvoicesFilterDetailsProps {
  filters: SearchHandledInvoicesFilter;
  applyFilters: (filters: SearchHandledInvoicesFilter) => void;
}

export const HandledInvoicesFilterDetails: FC<HandledInvoicesFilterDetailsProps> = ({ filters, applyFilters }) => {
  const { t } = useTranslation();

  const filtersApplied =
    filters?.invoiceDueDate?.fromDate.length > 0 ||
    filters?.invoiceDueDate?.toDate.length > 0 ||
    filters?.createdDate?.from?.date?.length > 0 ||
    filters?.createdDate?.to?.date?.length > 0;

  const removeFromInvoiceDueDateFilter = () => {
    applyFilters({
      ...filters,
      invoiceDueDate: { fromDate: '', toDate: filters.invoiceDueDate?.toDate },
    });
  };

  const removeToInvoiceDueDateFilter = () => {
    applyFilters({
      ...filters,
      invoiceDueDate: { fromDate: filters.invoiceDueDate?.fromDate, toDate: '' },
    });
  };

  const removeFromCreatedDateFilter = () => {
    applyFilters({
      ...filters,
      createdDate: {
        from: { date: '', time: '' },
        to: filters?.createdDate?.to,
      },
    });
  };

  const removeToCreatedDateFilter = () => {
    applyFilters({
      ...filters,
      createdDate: {
        from: filters?.createdDate?.from,
        to: { date: '', time: '' },
      },
    });
  };

  const removeAllFilters = () => {
    applyFilters({
      searchInput: '',
      invoiceDueDate: {
        fromDate: '',
        toDate: '',
      },
      invoiceStatuses: ['HANDLED'],
      createdDate: {
        from: {
          date: '',
          time: '',
        },
        to: {
          date: '',
          time: '',
        },
      },
    });
  };

  return (
    <div className="handled-invoices-filter-details">
      <Typography variant="caption" bold>
        Filter:
      </Typography>
      {!filtersApplied && (
        <Typography variant="caption" italic>
          Inga filter
        </Typography>
      )}
      {filtersApplied && (
        <>
          {filters?.invoiceDueDate.fromDate?.length > 0 && (
            <FilterButton
              label={filters?.invoiceDueDate.fromDate + ' ' + t('economy:search-handled-invoices-filters.start-date')}
              onClick={removeFromInvoiceDueDateFilter}
            />
          )}
          {filters?.invoiceDueDate.toDate?.length > 0 && (
            <FilterButton
              label={filters?.invoiceDueDate.toDate + ' ' + t('economy:search-handled-invoices-filters.end-date')}
              onClick={removeToInvoiceDueDateFilter}
            />
          )}
          {filters?.createdDate?.from?.date?.length > 0 && (
            <FilterButton
              label={
                `${filters?.createdDate?.from?.date} ${
                  filters?.createdDate?.from?.time === undefined ? '' : filters?.createdDate?.from?.time
                } ` + t('economy:search-handled-invoices-filters.from-created-date')
              }
              onClick={removeFromCreatedDateFilter}
            />
          )}
          {filters?.createdDate?.to?.date?.length > 0 && (
            <FilterButton
              label={
                `${filters?.createdDate?.to?.date} ${
                  filters?.createdDate?.to?.time === undefined ? '' : filters?.createdDate?.to?.time
                } ` + t('economy:search-handled-invoices-filters.to-created-date')
              }
              onClick={removeToCreatedDateFilter}
            />
          )}
          {
            <Button
              type="warning"
              size="small"
              label={t('economy:search-handled-invoices-filters.remove-all')}
              onClick={removeAllFilters}
            />
          }
        </>
      )}
    </div>
  );
};

export default HandledInvoicesFilterDetails;
