export interface RejectionDetail {
  id: string;
  createdDate: Date;
  rejectedBy: RejectedBy;
  rejectionCause: RejectionCause;
  rejectionData: string;
}

export type RejectedBy = 'SYSTEM' | 'USER';
export const rejectedBy: RejectedBy[] = ['SYSTEM', 'USER'];

export type RejectionCause =
  | 'ROWS_SUM_NOT_EQUAL_TO_TOTAL_AMOUNT'
  | 'CONTRACT_AGREEMENT_NOT_FOUND'
  | 'INSURANCE_PROVIDER_NOT_FOUND'
  | 'DUPLICATE_INVOICE'
  | 'CHECKPOINT_DECLINED'
  | 'EMPLOYEES_MISSING'
  | 'EMPLOYEE_MISSING'
  | 'INSURANCE_TYPE_MAPPING_MISSING'
  | 'INVALID_INVOICE_HEADER_PERIOD'
  | 'INVALID_INVOICE_ROW_PERIOD'
  | 'POSTING_RULE_MISSING'
  | 'INVALID_ACCOUNT_NUMBER'
  | 'INSURANCE_PROVIDER_NOT_ALLOWED_FOR_EMPLOYER'
  | 'MISSING_VAT_ACCOUNT'
  | 'MISSING_ROUNDING_ACCOUNT'
  | 'CONTRACT_TERMINATED';
