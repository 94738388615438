import { Checkbox, SortDirection, TableBody, TableHead, TableRow, Td, Th } from 'fgirot-k2-ui-components';
import React, { FC, useState } from 'react';
import TableBase from 'src/components/Common/TableBase';
import { Payment } from 'src/types/Payment';
import { PaymentsTableSortField } from './PaymentsTableSortField';
import PaymentDrawer from '../PaymentDrawer';
import './payments-table.scss';
import { getPaymentTableHeads } from '../utils/paymentUtils';
import { useTranslation } from 'react-i18next';
import { formatTime } from 'src/util/date/PeriodUtil';
import { stringOrBlank } from 'src/util/StringUtil/StringUtil';
import { formatSweAmountText } from 'src/util/Number/AmountFormatter';

interface PaymentsTableProps {
  payments: Payment[];
  sortingProperty?: PaymentsTableSortField;
  sortDirection?: SortDirection;
  checked: { id: string; checked: boolean }[];
  applySorting(property: string, direction: SortDirection);
  onChange: (id: string) => void;
}
const PaymentsTable: FC<PaymentsTableProps> = ({
  payments,
  sortingProperty,
  sortDirection,
  checked,
  applySorting,
  onChange,
}) => {
  const [selectedPaymentId, setSelectedPaymentId] = useState<string>(undefined);
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
  const { t } = useTranslation();

  const handleOpenPaymentDrawer = (id: string) => {
    setSelectedPaymentId(id);
  };

  const handleClosePaymentDrawer = () => {
    setSelectedPaymentId(undefined);
  };

  const handleSort = (property: PaymentsTableSortField) => {
    if (property == sortingProperty) {
      applySorting(property, sortDirection == 'UP' ? 'DOWN' : 'UP');
    } else {
      applySorting(property, sortDirection);
    }
  };

  const tableHeads = getPaymentTableHeads(handleSort);

  const renderPaymentTableData = (
    payment: Payment,
    title: string,
    checked: { id: string; checked: boolean }[],
    onChange: (id: string) => void,
    t: any,
  ) => {
    switch (title) {
      case '':
        return (
          <div onClick={(e) => e.stopPropagation()}>
            <Checkbox
              checked={checked.find((box) => box.id === payment.id)?.checked ?? false}
              onChange={() => onChange(payment.id)}
            />
          </div>
        );
      case t('economy:payments-tab.table-head.employer'):
        return stringOrBlank(payment.employer?.legalName);
      case t('economy:payments-tab.table-head.insurance-provider'):
        return stringOrBlank(payment.invoice.insuranceProvider?.legalName);
      case t('economy:payments-tab.table-head.invoice-number'):
        return payment.invoice.invoiceNumber;
      case t('economy:payments-tab.table-head.amount'):
        return formatSweAmountText(`${payment.amount}`);
      case t('economy:payments-tab.table-head.payment-status'):
        return stringOrBlank(t(`payment:payment-status.${payment.paymentStatus}`));
      case t('economy:payments-tab.table-head.payment-due-date'):
        return stringOrBlank(payment.paymentDueDate);
      case t('economy:payments-tab.table-head.payment-created-date'):
        return stringOrBlank(payment.paymentCreatedDate).substring(0, 10);
      case t('economy:payments-tab.table-head.time-stamp'):
        return formatTime(payment.paymentCreatedDate);
      case t('economy:payments-tab.table-head.payment-service-provider'):
        return t(`payment:payment-service-provider.${payment.paymentServiceProvider}`);
      case t('economy:payments-tab.table-head.bank'):
        return t(`payment:bank.${payment.bank}`);
      case t('economy:payments-tab.table-head.account-from'):
        return payment.accountFrom;
      case t('economy:payments-tab.table-head.account-to'):
        return payment.accountTo;
      case t('economy:payments-tab.table-head.description'):
        return payment.numberOfNotes > 0
          ? `${payment.numberOfNotes} ${
              payment.numberOfNotes === 1 ? t('economy:payments-tab.note') : t('economy:payments-tab.notes')
            }`
          : '-';
      case t('economy:payments-tab.table-head.payment-error-code'):
        return payment.errorCode ? t(`economy:payments-tab.error-codes.${payment.errorCode}`) : '-';
      case t('economy:payments-tab.table-head.payment-reference'):
        return payment.paymentId;
      default:
        return null;
    }
  };

  return (
    <div className="payments-table">
      <TableBase compressed hiddenColumns={hiddenColumns} setHiddenColumns={setHiddenColumns}>
        <TableHead filled hiddenColumns={hiddenColumns}>
          <TableRow>
            {tableHeads.map((head, index) => (
              <Th
                compressed
                includeSortButtons={!!head.handleSortDirectionChange}
                sortDirection={head.fieldName === sortingProperty ? sortDirection : undefined}
                handleSortDirectionChange={head.handleSortDirectionChange}
                key={`PaymentTableHead-${head.title}-${index}`}
                excludeColumn={head.excludeColumn ? head.excludeColumn : null}
              >
                {head.title}
              </Th>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {payments.map((payment) => (
            <TableRow
              className="payments-table-row"
              data-testid={`payments-table-row__${payment.id}`}
              key={payment.id}
              onClick={(e) => {
                if ((e.target as HTMLElement).tagName !== 'INPUT') {
                  handleOpenPaymentDrawer(payment.id);
                }
              }}
            >
              {tableHeads
                .filter((head) => !hiddenColumns.includes(head.title))
                .map((head, index) => (
                  <Td compressed key={`row-${payment.id}-${index}`}>
                    {renderPaymentTableData(payment, head.title, checked, onChange, t)}
                  </Td>
                ))}
            </TableRow>
          ))}
        </TableBody>
      </TableBase>
      {selectedPaymentId !== undefined && (
        <PaymentDrawer
          open={!!selectedPaymentId}
          onClose={handleClosePaymentDrawer}
          payment={payments.find((payment) => payment.id === selectedPaymentId)}
        />
      )}
    </div>
  );
};

export default PaymentsTable;
