import { Button, Drawer, Icon, ListGroup, Pill, Typography } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ListProperty from 'src/components/Common/ListProperty';
import { stringOrBlank } from 'src/util/StringUtil/StringUtil';
import { RejectionCause, RejectionDetail } from 'src/types/RejectionDetail';
import { Invoice } from 'src/types/Invoice';

interface RejectionDetailsProps {
  invoice: Invoice;
  rejectionDetail: RejectionDetail;
  onClose: () => void;
  onBackClick: () => void;
}

const RejectionDetails: FC<RejectionDetailsProps> = ({ invoice, rejectionDetail, onClose, onBackClick }) => {
  const { t } = useTranslation();

  const getRejectionDataLabel = (type: RejectionCause) => {
    switch (type) {
      case 'ROWS_SUM_NOT_EQUAL_TO_TOTAL_AMOUNT':
        return t('economy:rejected-invoice-drawer.rejection-details-drawer-tab.amount-label');
      case 'CONTRACT_AGREEMENT_NOT_FOUND':
        return t('economy:rejected-invoice-drawer.rejection-details-drawer-tab.agreement-number-label');
      case 'CONTRACT_TERMINATED':
        return t('economy:rejected-invoice-drawer.rejection-details-drawer-tab.agreement-number-label');
      case 'INSURANCE_PROVIDER_NOT_FOUND':
        return t('economy:rejected-invoice-drawer.rejection-details-drawer-tab.org-number-label');
      case 'DUPLICATE_INVOICE':
        return t('economy:rejected-invoice-drawer.rejection-details-drawer-tab.invoice-header-label');
      case 'CHECKPOINT_DECLINED':
        return '';
      case 'EMPLOYEES_MISSING':
        return t('economy:rejected-invoice-drawer.rejection-details-drawer-tab.person-number-label');
      case 'EMPLOYEE_MISSING':
        return t('economy:rejected-invoice-drawer.rejection-details-drawer-tab.person-number-label');
      case 'INSURANCE_TYPE_MAPPING_MISSING':
        return t('economy:rejected-invoice-drawer.rejection-details-drawer-tab.insurance-type-key-label');
      case 'INVALID_INVOICE_HEADER_PERIOD':
        return t('economy:rejected-invoice-drawer.rejection-details-drawer-tab.invoice-header-label');
      case 'INVALID_INVOICE_ROW_PERIOD':
        return t('economy:rejected-invoice-drawer.rejection-details-drawer-tab.invoice-header-label');
      case 'POSTING_RULE_MISSING':
        return t('economy:rejected-invoice-drawer.rejection-details-drawer-tab.insurance-type-key-label');
      case 'INVALID_ACCOUNT_NUMBER':
        return t('economy:rejected-invoice-drawer.rejection-details-drawer-tab.account-number-label');
      case 'INSURANCE_PROVIDER_NOT_ALLOWED_FOR_EMPLOYER':
        return t(
          'economy:rejected-invoice-drawer.rejection-details-drawer-tab.insurance-provider-not-allowed-employer-label',
        );
      default:
        return '-';
    }
  };

  return (
    <Drawer
      leftButton={
        <Button type="link" label={t('common:back')} icon={<Icon type="ArrowLeft" />} onClick={onBackClick} />
      }
      type="elements"
      onClose={onClose}
    >
      <div className="rejected-invoice-drawer__title">
        <Typography variant="h3" component="h3" bold>
          {t('economy:rejected-invoice-drawer.drawer-title') + ` ${invoice.invoiceNumber}`}
        </Typography>
        <div className="rejected-invoice-drawer__sub-title">
          <Typography>{invoice.employer.legalName}</Typography>
          <Pill label={invoice.insuranceProvider?.legalName} />
        </div>
      </div>
      <ListGroup variant="inline-edit">
        <ListProperty
          label={t('economy:rejected-invoice-drawer.rejection-details-drawer-tab.created-date-label')}
          value={String(rejectionDetail.createdDate).substring(0, 10)}
        />
        <ListProperty
          label={t('economy:rejected-invoice-drawer.rejection-details-drawer-tab.rejected-by-label')}
          value={stringOrBlank(t(`invoice:rejected-by.${rejectionDetail.rejectedBy}`))}
        />
        <ListProperty
          label={t('economy:rejected-invoice-drawer.rejection-details-drawer-tab.rejection-cause-label')}
          value={stringOrBlank(t(`invoice:rejection-cause.${rejectionDetail.rejectionCause}`))}
        />
        {rejectionDetail.rejectionCause !== 'CHECKPOINT_DECLINED' && rejectionDetail.rejectionData && (
          <ListProperty
            label={getRejectionDataLabel(rejectionDetail?.rejectionCause)}
            value={stringOrBlank(rejectionDetail.rejectionData)}
          />
        )}
      </ListGroup>
    </Drawer>
  );
};

export default RejectionDetails;
